import Cookie from 'js-cookie';
import { createMUPImpression } from '@spotify-internal/event-definitions/es5/events/createMUPImpression';
import { createMUPAction } from '@spotify-internal/event-definitions/es5/events/createMUPAction';
import { PlanType } from 'lib/plans';
import { MemberId } from 'types';
import { ChildAccountSetting } from 'data/homeHub/useChildAccountSettings';
import { createMUPActionNonAuth } from '@spotify-internal/event-definitions/es5/events/createMUPActionNonAuth';
import { createMUPImpressionNonAuth } from '@spotify-internal/event-definitions/es5/events/createMUPImpressionNonAuth';

type PageType =
  | 'purchase_confirmation'
  | 'user_has_plan_hopped_error'
  | 'user_has_failed_reverification_error'
  | 'change_account'
  | 'reverification_success'
  | 'reverification_failure'
  | 'reverification_enter_address'
  | 'reverification_address_confirmed'
  | 'reverification_link_expired'
  | 'reverification_redirect'
  | 'progress_indicator_one'
  | 'progress_indicator_two'
  | 'address'
  | 'invite'
  | 'member'
  | 'home_hub'
  | 'how_to_invite_members'
  | 'migration_info'
  | 'join_confirmation'
  | 'transition_error'
  | 'address_autocomplete'
  | 'feature_comparison'
  | 'join_landing_page'
  | 'member_remove_page'
  | 'country_mismatch_error'
  | 'upgrade_plan';

type ErrorPageType =
  | 'address_mismatch'
  | 'country_mismatch'
  | 'generic'
  | 'invite_has_expired'
  | 'join_own_plan'
  | 'partner_transition'
  | 'plan_is_full'
  | 'user_has_failed_reverification'
  | 'user_has_plan_hopped'
  | 'user_has_failed_generic_check'
  | 'restricted_country'
  | 'member_removal_page'
  | 'audiobooks_offer_transition'
  | 'gpb_offer_transition';

type ImpressionType =
  | 'buy_additional_accounts_cta'
  | 'member_removal_confirmation_modal'
  | 'member_removal_confirmation_with_downgrade_modal'
  | 'confirm_downgrade_modal'
  | 'downgrade_card'
  | 'downgrade_item';

type ActionType = {
  progress_indicator_one: 'continue' | 'dismiss';
  purchase_confirmation: 'set_address';
  user_has_plan_hopped_error: 'see_plans';
  user_has_failed_reverification_error: 'see_plans';
  change_account: 'change' | 'confirm';
  reverification_enter_address: 'save_address' | 'faq';
  reverification_address_confirmed: 'open_spotify';
  reverification_link_expired: 'open_spotify';
  reverification_success: 'open_spotify';
  reverification_failure: 'try_again' | 'see_plans' | 'get_help';
  progress_indicator_two: 'continue' | 'dismiss';
  address:
    | 'faq'
    | 'consent'
    | 'save_address'
    | 'change_address_dialog_view'
    | 'change_address_dialog_confirm'
    | 'change_address_dialog_cancel';
  address_autocomplete: 'select' | 'clear' | 'faq' | 'edit' | 'confirm';
  home_hub:
    | 'open_invite'
    | 'change_address'
    | 'change_address_modal_view'
    | 'change_address_modal_cancel'
    | 'remove_sub'
    | 'open_member'
    | 'migration_confirm_address'
    | 'share_invite'
    | 'buy_additional_accounts_click'
    | 'downgrade_click';
  join_confirmation: 'open_spotify';
  invite:
    | 'faq'
    | 'download_google_play'
    | 'download_app_store'
    | 'migration_confirm_address'
    | 'abort_native_sharing';
  member:
    | 'remove_sub'
    | 'allow_explicit_content'
    | 'disallow_explicit_content'
    | 'parental_setting_change';
  feature_comparison: 'cancel' | 'continue';
  transition_error: 'manage_account' | 'go_to_google_play_store';
  member_remove_page: 'remove_sub' | 'cancel' | 'close';
  country_mismatch_error: 'see_plans';
  join_landing_page: 'join' | 'toc';
  upgrade_plan: 'add_account' | 'remove_account' | 'continue';
  member_removal_confirmation_modal: 'confirm';
  member_removal_confirmation_with_downgrade_modal: 'confirm';
  confirm_downgrade_modal: 'confirm';
};

function impression(
  pageOrImpression: PageType | ErrorPageType | ImpressionType,
  planType: PlanType,
  properties?: Record<string, string>,
) {
  return createMUPImpression({
    sp_t: Cookie.get('sp_t'),
    plan_type: planType,
    element: pageOrImpression,
    properties,
  });
}

function impressionNonAuth(
  pageOrImpression: PageType | ErrorPageType | ImpressionType,
  planType: PlanType,
  properties?: Record<string, string>,
) {
  return createMUPImpressionNonAuth({
    sp_t: Cookie.get('sp_t'),
    plan_type: planType,
    element: pageOrImpression,
    properties,
  });
}

function action<K extends keyof ActionType>(
  pageOrImpression: K,
  actionType: ActionType[K],
  planType: PlanType,
  properties?: Record<string, string>,
) {
  return createMUPAction({
    sp_t: Cookie.get('sp_t'),
    plan_type: planType,
    action: actionType,
    element: pageOrImpression,
    properties,
  });
}

function actionNonAuth<K extends keyof ActionType>(
  pageOrImpression: K,
  actionType: ActionType[K],
  planType: PlanType,
  properties?: Record<string, string>,
) {
  return createMUPActionNonAuth({
    sp_t: Cookie.get('sp_t'),
    plan_type: planType,
    action: actionType,
    element: pageOrImpression,
    properties,
  });
}

export const progressIndicatorOne = {
  pageView: (planType: PlanType) =>
    impression('progress_indicator_one', planType),
  continue: (planType: PlanType) =>
    action('progress_indicator_one', 'continue', planType),
  dismiss: (planType: PlanType) =>
    action('progress_indicator_one', 'dismiss', planType),
};

type ErrorData = {
  type: string;
  code: string;
};

export const error = {
  pageView: (errorType: ErrorPageType, planType: PlanType, data: ErrorData) =>
    impression(errorType, planType, data),
};

export const purchaseConfirmation = {
  pageView: (planType: PlanType) =>
    impression('purchase_confirmation', planType),
  setAddress: (planType: PlanType) =>
    action('purchase_confirmation', 'set_address', planType),
};

export const userHasPlanHoppedError = {
  seePlans: (planType: PlanType) =>
    action('user_has_plan_hopped_error', 'see_plans', planType),
};

export const userHasFailedReverificationError = {
  seePlans: (planType: PlanType) =>
    action('user_has_failed_reverification_error', 'see_plans', planType),
};
export type ChangeAccountContext = 'sub_join' | 'reverification';

export const changeAccount = {
  pageView: (planType: PlanType, context: ChangeAccountContext) =>
    impression('change_account', planType, { context }),
  change: (planType: PlanType, context: ChangeAccountContext) =>
    action('change_account', 'change', planType, { context }),
  confirm: (planType: PlanType, context: ChangeAccountContext) =>
    action('change_account', 'confirm', planType, { context }),
};

export const reverificationEnterAddress = {
  pageView: (planType: PlanType) =>
    impression('reverification_enter_address', planType),
  saveAddress: (planType: PlanType) =>
    action('reverification_enter_address', 'save_address', planType),
  faq: (planType: PlanType) =>
    action('reverification_enter_address', 'faq', planType),
};

export const reverificationAddressConfirmed = {
  pageView: (planType: PlanType) =>
    impression('reverification_address_confirmed', planType),
  openSpotify: (planType: PlanType) =>
    action('reverification_address_confirmed', 'open_spotify', planType),
};

export const reverificationLinkExpired = {
  pageView: (planType: PlanType) =>
    impression('reverification_link_expired', planType),
  openSpotify: (planType: PlanType) =>
    action('reverification_link_expired', 'open_spotify', planType),
};

export const reverificationSuccess = {
  pageView: (planType: PlanType) =>
    impression('reverification_success', planType),
  openSpotify: (planType: PlanType) =>
    action('reverification_success', 'open_spotify', planType),
};

export const reverificationRedirect = {
  pageView: (planType: PlanType) =>
    impression('reverification_redirect', planType),
};

export const reverificationFailure = {
  pageView: (planType: PlanType) =>
    impression('reverification_failure', planType),
  tryAgain: (planType: PlanType) =>
    action('reverification_failure', 'try_again', planType),
  seePlans: (planType: PlanType) =>
    action('reverification_failure', 'see_plans', planType),
  getHelp: (planType: PlanType) =>
    action('reverification_failure', 'get_help', planType),
};

export const progressIndicatorTwo = {
  pageView: (planType: PlanType) =>
    impression('progress_indicator_two', planType),
  continue: (planType: PlanType) =>
    action('progress_indicator_two', 'continue', planType),
  dismiss: (planType: PlanType) =>
    action('progress_indicator_two', 'dismiss', planType),
};

export type AddressContext =
  | 'setup'
  | 'sub_join'
  | 'migration'
  | 'change_address'
  | 'reverification';

export const address = {
  pageView: (planType: PlanType, context: AddressContext) =>
    impression('address', planType, { context }),
  faq: (planType: PlanType, context: AddressContext) =>
    action('address', 'faq', planType, { context }),
  consent: (planType: PlanType, context: AddressContext) =>
    action('address', 'consent', planType, { context }),
  saveAddress: (planType: PlanType, context: AddressContext) =>
    action('address', 'save_address', planType, { context }),
  changeAddressDialogView: (planType: PlanType) =>
    action('address', 'change_address_dialog_view', planType, {
      context: 'change_address',
    }),
  changeAddressDialogConfirm: (planType: PlanType) =>
    action('address', 'change_address_dialog_confirm', planType, {
      context: 'change_address',
    }),
  changeAddressDialogCancel: (planType: PlanType) =>
    action('address', 'change_address_dialog_cancel', planType, {
      context: 'change_address',
    }),
};

export const addressAutocomplete = {
  select: (planType: PlanType, context: AddressContext) =>
    action('address_autocomplete', 'select', planType, { context }),
  clear: (planType: PlanType, context: AddressContext) =>
    action('address_autocomplete', 'clear', planType, { context }),
  faq: (planType: PlanType, context: AddressContext) =>
    action('address_autocomplete', 'faq', planType, { context }),
  edit: (planType: PlanType, context: AddressContext) =>
    action('address_autocomplete', 'edit', planType, { context }),
  confirm: (planType: PlanType, context: AddressContext) =>
    action('address_autocomplete', 'confirm', planType, { context }),
};

type HomeHubContext = 'hero' | 'members_card';

export const homeHub = {
  pageView: (planType: PlanType) => impression('home_hub', planType),
  openInvite: (planType: PlanType, context: HomeHubContext) =>
    action('home_hub', 'open_invite', planType, { context }),
  changeAddress: (planType: PlanType) =>
    action('home_hub', 'change_address', planType),
  changeAddressModalView: (planType: PlanType) =>
    action('home_hub', 'change_address_modal_view', planType),
  changeAddressModalCancel: (planType: PlanType) =>
    action('home_hub', 'change_address_modal_cancel', planType),
  removeSub: (planType: PlanType) => action('home_hub', 'remove_sub', planType),
  buyAdditionalAccountsImpression: (planType: PlanType) =>
    impression('buy_additional_accounts_cta', planType),
  buyAdditionalAccounts: (planType: PlanType) =>
    action('home_hub', 'buy_additional_accounts_click', planType),
  downgrade: (planType: PlanType) =>
    action('home_hub', 'downgrade_click', planType),
  downgradeItemImpression: (planType: PlanType) =>
    impression('downgrade_item', planType),
  openMemberDetails: (planType: PlanType) =>
    action('home_hub', 'open_member', planType),
};

export const joinConfirmation = {
  pageView: (planType: PlanType) => impression('join_confirmation', planType),
  openSpotify: (planType: PlanType) =>
    action('join_confirmation', 'open_spotify', planType),
};

export const invite = {
  pageView: (planType: PlanType) => impression('invite', planType),
  faq: (planType: PlanType) => action('invite', 'faq', planType),
  downloadGooglePlay: (planType: PlanType) =>
    action('invite', 'download_google_play', planType),
  downloadAppStore: (planType: PlanType) =>
    action('invite', 'download_app_store', planType),
  migrationConfirmAddress: (planType: PlanType) =>
    action('invite', 'migration_confirm_address', planType),
  abortNativeSharing: (planType: PlanType) =>
    action('invite', 'abort_native_sharing', planType),
};

export const member = {
  pageView: (memberId: MemberId, planType: PlanType) =>
    impression('member', planType, { member_id: memberId }),
  downgradeCard: (planType: PlanType) => impression('downgrade_card', planType),
  removeSub: (memberId: MemberId, planType: PlanType) =>
    action('member', 'remove_sub', planType, { member_id: memberId }),
  allowExplicitContent: (memberId: MemberId, planType: PlanType) =>
    action('member', 'allow_explicit_content', planType, {
      member_id: memberId,
    }),
  disallowExplicitContent: (memberId: MemberId, planType: PlanType) =>
    action('member', 'disallow_explicit_content', planType, {
      member_id: memberId,
    }),
  managedAccountSettingChange: (
    memberId: MemberId,
    settingName: ChildAccountSetting,
    settingValue: boolean,
    planType: PlanType,
  ) =>
    action('member', 'parental_setting_change', planType, {
      member_id: memberId,
      setting_name: settingName,
      setting_value: String(settingValue),
    }),
};

export const featureComparison = {
  pageView: (currentOffer: string, planType: PlanType) =>
    impression('feature_comparison', planType, {
      current_offer: currentOffer,
    }),
  continue: (currentOffer: string, planType: PlanType) =>
    action('feature_comparison', 'continue', planType, {
      current_offer: currentOffer,
    }),
  cancel: (currentOffer: string, planType: PlanType) =>
    action('feature_comparison', 'cancel', planType, {
      current_offer: currentOffer,
    }),
};

export const transitionError = {
  manageAccount: (currentOffer: string, planType: PlanType) =>
    action('transition_error', 'manage_account', planType, {
      current_offer: currentOffer,
    }),
  goToGooglePlayStore: (planType: PlanType) =>
    action('transition_error', 'go_to_google_play_store', planType),
};

export const howToInviteMembers = {
  pageView: (planType: PlanType) =>
    impression('how_to_invite_members', planType),
};

export const removeMember = {
  pageView: (planType: PlanType) => impression('member_remove_page', planType),
  removeSub: (planType: PlanType) =>
    action('member_remove_page', 'remove_sub', planType),
  cancel: (planType: PlanType) =>
    action('member_remove_page', 'cancel', planType),
};

export const countryMismatchError = {
  seePlans: (planType: PlanType) =>
    action('country_mismatch_error', 'see_plans', planType),
};

export const joinLandingPageAuth = {
  pageView: (planType: PlanType) => impression('join_landing_page', planType),
  join: (planType: PlanType) => action('join_landing_page', 'join', planType),
  toc: (planType: PlanType) => action('join_landing_page', 'toc', planType),
};

export const joinLandingPageNonAuth = {
  pageView: (planType: PlanType) =>
    impressionNonAuth('join_landing_page', planType),
  join: (planType: PlanType) =>
    actionNonAuth('join_landing_page', 'join', planType),
  toc: (planType: PlanType) =>
    actionNonAuth('join_landing_page', 'toc', planType),
};

type InviteThroughType =
  | 'copy_link'
  | 'whatsapp'
  | 'messenger'
  | 'email'
  | 'field_click'
  | 'native';

export const inviteSharing = {
  shareInvite: (inviteThrough: InviteThroughType, planType: PlanType) =>
    action('home_hub', 'share_invite', planType, { inviteThrough }),
};

export const upgradePlan = {
  pageView: (planType: PlanType) => impression('upgrade_plan', planType),
  addAccount: (planType: PlanType) =>
    action('upgrade_plan', 'add_account', planType),
  removeAccount: (planType: PlanType) =>
    action('upgrade_plan', 'remove_account', planType),
  continue: (planType: PlanType) =>
    action('upgrade_plan', 'continue', planType),
};

export const modals = {
  confirmMemberRemoval: {
    impression: (memberId: MemberId, planType: PlanType) =>
      impression('member_removal_confirmation_modal', planType, { memberId }),
    confirm: (memberId: MemberId, planType: PlanType) =>
      action('member_removal_confirmation_modal', 'confirm', planType, {
        memberId,
      }),
  },
  confirmMemberRemovalWithDowngrade: {
    impression: (memberId: MemberId, planType: PlanType) =>
      impression('member_removal_confirmation_with_downgrade_modal', planType, {
        memberId,
      }),
    confirm: (memberId: MemberId, planType: PlanType) =>
      action(
        'member_removal_confirmation_with_downgrade_modal',
        'confirm',
        planType,
        {
          memberId,
        },
      ),
  },
  confirmDowngrade: {
    impression: (planType: PlanType) =>
      impression('confirm_downgrade_modal', planType),
    confirm: (planType: PlanType) =>
      action('confirm_downgrade_modal', 'confirm', planType),
  },
};
