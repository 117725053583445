import { trackEvent } from 'lib/tracking';
import { useEffect } from 'react';
import { MUPImpressionNonAuthEvent } from '@spotify-internal/event-definitions/events/createMUPImpressionNonAuth';
import { MUPImpressionEvent } from '@spotify-internal/event-definitions/events/createMUPImpression';

type EventData = MUPImpressionNonAuthEvent | MUPImpressionEvent;

export const useImpression = (event: EventData) => {
  useEffect(() => {
    trackEvent(event);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
